import { SupplierInvoiceModel, SupplierModel, UserModel } from '.';
import { unescape } from 'html-escaper';

/*
  status : Number
    0 = Pending
    4 = Completed
*/

export class SupplierReceiptModel {
  constructor(data={}) {
    this._id = data._id? data._id: null;
    
    this.code = data.code? unescape(data.code): null;
    
    this.supplierInvoice = new SupplierInvoiceModel(data.supplierInvoice? data.supplierInvoice: {});

    this.supplier = new SupplierModel(data.supplier? data.supplier: {});
    this.salesManager = new UserModel(data.salesManager? data.salesManager: {});

    this.orderAt = data.orderAt? data.orderAt: null;
    this.payment = Number(data.payment? data.payment: 0);
    this.finalPayment = data.finalPayment || data.finalPayment===0 
      ? Number(data.finalPayment): null;
      
    this.items = (data?.items || []).map(d => ({
      item: d.item? unescape(d.item): null,
      total: d.total || d.total===0? Number(d.total): null,
    }));
    this.grandTotal = data.grandTotal || data.grandTotal===0? Number(data.grandTotal): null;

    this.status = data.status? data.status: 0;
  }

  isValid() {
    return this._id? true: false;
  }

  displayStatus() {
    if(this.isValid()){
      if(this.status === 4) return (<span className="ss-tag lg status-4">ชำระเเล้ว</span>);
      else return (<span className="ss-tag lg">รอชำระ</span>);
    }else{
      return (<span className="ss-tag lg">รอชำระ</span>);
    }
  }
}
